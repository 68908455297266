// HEADERS
.page-title {
    margin-left: -14px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: lighten($clr-nav-top-accent, 2.5%);
    border-bottom: 1px solid $clr-text-dark-divider;
    width: calc(100% + 29px);
    .page-title-icon {
        con-icon-by-name {
            text-align: center;
            float: left;
            width: 36px;
            height: 36px;
            border: 2px solid $clr-icon-dark-inactive;
            border-radius: 100%;
            margin-right: $spacer * .25;
            display: flex;
            align-items: flex-start;
            i {
                font-size: 20px;
                align-self: center;
                color: $clr-icon-dark-active;
                margin: 0 auto;
            }
        }
    }
    h3 {
        margin-top: 2px;
    }
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: lighten($clr-nav-top-accent, 2.5%);
    box-shadow: $shadow-2;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.sticky-header.non-sticky {
    position: relative;
}

//CHIP
.chip {
    display: inline-block;
    height: 32px;
    font-size: $font-size-sm;
    font-weight: $font-weight-medium;
    font-family: $font-family-headings;
    color: $clr-text-dark-secondary;
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: $clr-nav-top-accent;
    img {
        float: left;
        margin: 0 8px 0 -12px;
        border-radius: 50%;
    }
    &.chip-inverse {
        color: $clr-text-light-primary;
        background-color: $gray-800;
        &.chip-link {
            color: $clr-text-light-primary;
            i {
                color: $clr-text-light-primary;
            }
        }
    }
    &.chip-link {
        cursor: pointer;
        transition: $btn-transition;
        color: $clr-text-dark-secondary;
        &:hover {
            background-color: $primary;
            color: $clr-text-light-primary;
            i {
                color: $clr-text-light-primary;
            }
        }
        i {
            transition: $btn-transition;
            color: $clr-text-dark-secondary;
        }
    }
    &.chip-primary {
        background-color: $primary;
        color: $clr-text-light-primary;
    }
}

.settings-page .chip {
    margin-top: 3px;
    margin-bottom: 3px;
}

//TASKS
.tasks {
    display: flex;
    flex-direction: column;
    .task {
        display: flex;
        flex-direction: column;
        align-content: stretch;
        border-bottom: 1px solid $clr-text-dark-divider;
        &:last-child {
            border-bottom: 0;
        }
        .task-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            .task-data {
                display: flex;
                flex-direction: column;
                margin-left: $spacer;
                margin-top: $spacer * 0.5;
                margin-bottom: $spacer * 0.5;

            }
            .task-actions {
                display: flex;
                flex-direction: column;
                margin-left: auto;
                text-align: center;
                margin-right: $spacer;
            }
        }
        .task-comments {
            display: flex;
            flex-direction: column;
            margin-left: $spacer;
            margin-right: $spacer;
            padding-top: $spacer * 0.5;
            border-top: 1px solid $clr-text-dark-divider;
            .task-comment {
                display: flex;
                flex-direction: row;
                margin-bottom: $spacer;
            }
            form {
                margin-bottom: $spacer;
            }
        }
    }
}

//TIMELINE
.timeline {
    position: relative;
    list-style: none;
    padding: $spacer 0 0 0;
    left: 0;
    &:before {
        top: 0;
        bottom: 0;
        position: absolute;
        content: " ";
        width: 2px;
        background-color: $clr-text-dark-divider;
        left: 10px;
        margin-left: -1px;
    }
    li {
        position: relative;
        display: block;
        width: 100%;
        left: 0;
        white-space: nowrap;
        min-height: 30px;
        box-sizing: border-box;
    }
    .timeline-entry {
    position: relative;
    display: inline-block;
    width: 100%;
    left: 0;
    padding: 0;
    vertical-align: top;
    margin: 0 -3px 0 0;
    white-space: normal;
        .card {
            position: relative;
            margin-left: 40px;
            margin-bottom: $spacer;
            margin-right: 0;
            font-size: 85%;
            /*
            img, .fa {
                margin-right: $spacer * 0.75;
            }
            */
            &:after {
                content: "";
                position: absolute;
                top: 16px;
                right: auto;
                left: -9px;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 9px 0 9px 9px;
                border-color: transparent transparent transparent $clr-text-dark-divider;
                transform: rotate(180deg);
            }
            .timeline-comments {
                display: flex;
                flex-direction: column;
                margin-top: $spacer;
                padding-top: $spacer * 0.5;
                border-top: 1px solid $clr-text-dark-divider;
                .timeline-comment {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: $spacer;
                }
                form {
                    margin-bottom: $spacer;
                }
            }
            .btn-sm {
                padding: 0 $spacer/4;
                margin-top: $spacer/4;
            }
            .form-group {
                label {
                    small {
                        font-size: 100%;
                    }
                }
            }
        }
    }
    .timeline-circ {
    position: absolute;
    top: 6px;
    left: 10px;
    width: 38px;
    height: 38px;
    line-height: 20px;
    margin-left: -19px;
    text-align: center;
    background: #000;
    color: $clr-icon-light-active;
    border-width: 3px;
    border-style: solid;
    border-color: #fff !important;
    border-radius: 50%;
    box-shadow: $shadow-1;
        .fa {
            top: 0;
            margin: 0;
            font-size: 18px;
            line-height: 32px;
        }
    }
    .timeline-circ-linked {
        background: $primary;
        color: $clr-icon-light-active;
    }
}

.timeline-pill {
    cursor: pointer;
    margin-right: $spacer * 0.1;
}
.text-right {
    .timeline-pill {
        margin-right: 0;
        margin-left: $spacer * 0.1;
    }
}

//FORM FEEDBACK
.form-control-feedback {
    width: 100%;
    font-size: 80%;
    color: $danger;
    p {
        margin-bottom: 0;
    }
}

//NGX-DATATABLE
.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: $font-size-sm;
    .datatable-header {
      height: unset !important;
      .datatable-header-cell {
        vertical-align: bottom;
        padding: $table-cell-padding;
        border-bottom: 1px solid $gray-300;
        background-color: $table-head-bg;
        color: $table-head-color;
        font-size: $font-size-base;
        font-family: $headings-font-family;
        font-weight: $font-weight-medium;
        .datatable-header-cell-label {
          line-height: 1.5;
        }
      }
    }
    .datatable-body {
      .datatable-body-row {
        vertical-align: top;
        border-top: 1px solid $gray-300;
        &.datatable-row-even {
          background-color: rgba(0, 0, 0, 0.05);
        }
        &.active {
          background-color: $primary;
          color: $white;
        }
        .datatable-body-cell {
          padding: $table-cell-padding;
          text-align: left;
          vertical-align: top;
        }
      }
    }
    .datatable-footer {
      background: $table-head-bg;
      color: $table-head-color;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      margin-top: -1px;
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0 1.2rem;
        font-weight: $font-weight-bold;
      }
      .datatable-pager {
        margin: 0 10px;
        vertical-align: top;
        ul {
          li {
            margin: 10px 0px;
            &:not(.disabled) {
              &.active,
              &:hover {
                a {
                  background-color: $primary;
                  font-weight: $font-weight-bold;
                }
              }
            }
            &.disabled{
                a {
                    color: $clr-icon-light-inactive;
                }
            }
          }
        }
        a {
          height: 22px;
          min-width: 24px;
          line-height: 22px;
          padding: 0;
          border-radius: 3px;
          margin: 0 3px;
          text-align: center;
          vertical-align: top;
          text-decoration: none;
          vertical-align: bottom;
          color: #ededed;
        }
        .datatable-icon-left,
        .datatable-icon-skip,
        .datatable-icon-right,
        .datatable-icon-prev {
            font-family: FontAwesome;
            font-style: normal;
        }
        .datatable-icon-left {
            &:before {
                content: "\f053";
            }
        }
        .datatable-icon-skip {
            &:before {
                content: "\f051";
            }
        }
        .datatable-icon-right {
            &:before {
                content: "\f054";
            }
        }
        .datatable-icon-prev {
            &:before {
                content: "\f048";
            }
        }
      }
    }

    // Summary row styles
    .datatable-summary-row {
      .datatable-body-row {
        .datatable-body-cell {
            font-weight: bold;
        }
      }
    }
  }

//BADGE-TIME
.badge-time {
    font-size: 90%;
    i {
        cursor: pointer;
        opacity: 0.6;
        transition: all 150ms ease-in-out;
        &:hover {
            opacity: 1;
        }
    }
    .custom-select-sm {
        font-size: 100%;
    }
}

//JSON-EDITOR
.jsoneditor{
    border: none;

    .jsoneditor-menu{
        background-color: $clr-card-head;
    }
    .jsoneditor-statusbar{
        background-color: $clr-card-head;
        color: $white;
    }
    .ace_editor.ace-jsoneditor {
        min-height: calc(100vh - 250px);
        font-family: $font-family-monospace;
        font-size: 12px!important;
        .ace_scrollbar {
            &::-webkit-scrollbar-track{
                box-shadow: inset 0 0 5px rgba(0,0,0,0.3);
                background-color: $clr-body-bkg;
            }
            &::-webkit-scrollbar
            {
                width: 10px;
                height: 10px;
                background-color: $clr-body-bkg;
            }
            &::-webkit-scrollbar-thumb
            {
                background-color: $clr-nav-side-accent;
            }
        }
    }
}
.no-data {
    color: $clr-text-dark-primary;
    background-color: lighten($clr-nav-top-accent, 2.5%);
    border-color: $clr-text-dark-divider;
    margin: 1rem;
}

.hide-pagination .pagination {
    display: none;
}

//Dataframe
#responseElm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.dataframe {
    width: 100%;
    margin-bottom: 0;
    color: $clr-text-dark-primary;
    vertical-align: top;
    border: 1px solid #e0e0e0;
    font-family: $font-family-monospace;
    text-align: right;
    th, td {
        padding: 0.25rem;
        border: 1px solid #e0e0e0;
    }
    tbody {
        vertical-align: inherit;
        tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    thead th {
        vertical-align: bottom;
        border-bottom-color: #9e9e9e;
        border-bottom-width: 2px;
    }
}
table td.select-all {
    padding-top: 1rem;
}
.sidebar nav.nav a.nav-link.doc-icon {
    padding: 1rem;
}

//NG Select
.ng-select {
  @media screen and (min-width: 576px) {
    width: 18.5em;
  }
  .ng-option {
    text-align: start !important;
  }
  .ng-select-container {
    border: 1px solid #BDBDBD !important;
  }
}
.side-menu-link {
    cursor: pointer;
}
.taxonomy-td td {
    vertical-align: middle;
}
//tree node
.active-tree-node{
    background-color: #edf1f9;
    input{
        background-color: transparent;
    }
    .previous-value{
        background-color: transparent !important;
    }
}
.prev-val-ui{
    list-style-type: none;
    display: grid;
    font-weight: 600;
}
//locked report
.locked-report{
    .no-data{
        margin: 0px;
        background-color: #e4db66;
        border: none;
        margin-bottom: 1rem;
    }
    .no-data:before {
        font-family: FontAwesome;
        content: "\f023";
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
     }
     .no-data::after{
         content: 'This entity is locked!';
     }

}
//Approved survey
.approved-survey{
  .no-data{
      margin: 0px;
      background-color: #d4edda;
      border: none;
      margin-bottom: 1rem;
  }
  .no-data:before {
      font-family: FontAwesome;
      content: "\f023";
      display: inline-block;
      padding-right: 3px;
      vertical-align: middle;
      font-weight: 900;
   }
   .no-data::after{
       content: 'This survey has been approved!';
   }
   &.consensus-message{
       .no-data::after{
           content: "Survey approved! Changes are not allowed, and API sync is disabled for this survey.";
       }
    }

}
.locked-report.calculated{
    .no-data::after{
        content: 'Cannot edit as the values are pre-calculated.';
    }
}
.filter-icon {
    font-size: 20px;
    color: $clr-text-dark-secondary;
    padding-left: 15px;
    padding-right: 8px;
    position: relative;
    top: 15px;
    .reset {
        font-size: 25px;
        position: relative;
        top: 5px;
        cursor: pointer;
    }
}

.filter-btn {
    width: 100%;
    top: 18px;
    border: solid 1px $clr-text-dark-secondary;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-label {
    font-size: smaller;
    color: rgba(0, 0, 0, 0.38);
}
// Material Table
.mat-checkbox-layout {
    margin-bottom: 0.2rem;
}
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-bottom: 0px;
    top: 1px;
  }
  .disabled-switch{
    opacity: 0.7;
    cursor: default !important;
    .slider {
      cursor: not-allowed !important;
    }
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .switch-text {
    position: relative;
    top: 3px;
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    top: 3px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $primary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $primary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  .action-btn {
      min-width: 80px;
  }
  .report-menu {
      cursor: pointer;
  }
  .recent-search {
        padding: 0 1.25rem 1.25rem 1.25rem;
        display: flex;
        align-items: center;
    h4 {
        font-size: 14px;
        margin-bottom: 0;
        padding-right: 10px;
    }
    .chip {
        margin: 5px;
        cursor: pointer;
    }
  }
  .editor-year{
      width: 225px;
      text-align: right;
      color: $primary;
  }
.select-and-confirm {
    position: relative;
    top: 18px;
}
.mozenda-icon {
    padding: 0 5px 0 5px;
}
.mozenda-menu {
    padding-bottom: 14px;
    mat-form-field {
        top: 10px;
    }
    .filter-options {
        margin-right: 10px;
        .sort {
            width: 200px;
        }
    }

}
.mt-15 {
  margin-top: 15px;
}
.mozenda-sort{
    cursor: pointer;
    color: rgb(187, 186, 186);
   .active {
        color: rgb(112, 113, 114);
    }
}
 .hide-close .alert-dismissible .close {
    display: none;
  }
  .position-editor {
    .form-control {
        height: calc(1.5em + 0.75rem + 14px);
    }
    .table-button, .table-row-column span span{
        top: 6px;
        position: relative;
    }
  }
  .position-editor .dropdown-menu {
        max-height: 150px;
        overflow-y: auto;
  }
  .row-action {
    display: flex;
    span {
      padding-left: 1px;
    }
    .translate {
        min-width: 87px;
    }
  }
  .adjust-mt-5 {
    margin-top: 5px;
  }
  .ml-15 {
    margin-left: 15px;
  }


  .ml-status-created {
    background-color: #fff3cd;
    color: black;
  }

  .ml-status-closed {
    background-color: #d4edda;
    color: black;
  }

  .ml-status-in-progress {
    background-color: #e8d882;
    color: black;
  }

  .ml-status-submitted {
    background-color: #b0e7a8;
    color: black;
  }
  .ml-status-failed {
    background-color: #efa560;
    color: black;
  }

  .fa-ml-status-black {
    color: black !important;
  }
  .ml-message-list{
    padding: 20px;
  }
  .ml-adjust-btn {
    margin-bottom: 5px;
  }
  .modal-header button:focus {
    outline: none !important;
  }
  .filter-toggle {
    float: right;
    margin-top: -35px;
    cursor: pointer;
  }

  .adjust-mt-5 {
    margin-top: 5px;
  }

  .cursor-wait {
    cursor: wait;
  }

  .ml-15 {
    margin-left: 15px;
  }

  .pb-16 {
    padding-bottom: 16px;
  }
  .editor-select {
    width: 210px;
    margin-top: -6px;
  }
  .w-60 {
    width: 60px;
  }
  .grad {
    background-image: linear-gradient(to right, rgba(220,220,220,1), rgba(255,255,255,0)) !important;
    padding-top: 5px;
  }
  .half-width {
    width: 50%;
  }
  .pl-10{
    padding-left: 10px;
  }
  .website-link-positioning {
    padding: 5px 0 0 15px;
  }
  .entity-add-form-custom {
    margin-right: 15px;
    padding-right: 0;
    padding-left: 0;
  }
  .ml-annotation-failed {
    color: white;
    background-color: #B46479;
    border-color: #B46479;
  }
  .annotation-error-label {
    background-color: #724D6C !important;
  }
  .annotation-error .card-header {
    background-color: #724D6C !important;
  }
  .annotation-error con-entity-form-content con-pretty-entity-shower .btn-primary {
    background-color: #724D6C !important;
    border-color: #B46479;
  }
  .pl-0 {
    padding-left: 0px;
  }
  .delta-preloader {
    width: 35px;
    height: 35px;
    float: right;
  }
  .mt-10 {
    margin-top: 10px;
  }

  .table th, .table td {
    word-break: break-all !important;
  }

  .mt-15 {
    margin-top: 15px;
  }
  .full-width-custom {
    width: 100%;
  }
  .one-third-split {
    width: 40%;
    float: left;
  }
  .two-third-split-with-gutter {
    width: 60%;
    float: left;
    padding-left: 15px;
  }
  .p-t-b-5{
    padding: 10px 0px;
  }
  .p-15 {
    padding: 15px;
  }
  .mr-15 {
    margin-right: 15px;
  }

  .mb-15 {
    margin-bottom: 15px;
  }
  .grad-tile i {
    color: #cccccc !important;
  }


  .report-spec-pos {
    padding: 0px 5px 0px 10px;
  }
  .report-spec-pos a {
    color: white;
  }
  // sector mapping start
  .w-200 {
    width: 200px;
  }
  .w-250 {
    width: 250px;
  }
  .w-500 {
    width:500px;
  }
  .w-50px {
    width: 50px;
  }
  .w-60px {
    width: 60px;
  }
  .w-220px {
    width: 220px;
  }
  .w-115px {
    width: 115px;
  }
  .w-120px {
    width: 120px;
  }
  .w-90px {
    width: 90px;
  }
  .flex-1 {
    flex: 1;
  }
  // sector mapping end
  // lock set start
  .lock-set i {
    color: $primary !important;
  }
  con-company-report-side-menu .report-side-menu-item:hover i.fa-unlock {
    color: $clr-icon-light-inactive !important;
  }
  // lock set end
  .ngx-file-drop__drop-zone {
    border: 1px solid #cecece !important;
    border-radius: 0px !important;
    margin-top: 15px !important;
  }


  // editor custom class start
  .quote {
    color: #8cc540;
    font-style: italic;
  }

  .redText {
    color: Red;
    font-size: 12px;
    font-family: Arial;
  }

  h1.titleText {
    color: #69a8cf;
    text-align: left;
    margin-top: 40px;
    font-weight: normal;
  }
  .boldText {
    font-weight: bold;
  }
  angular-editor table,  angular-editor th, angular-editor  td {
    border: 1px solid black;
  }
  // editor custom class end
 .excerpt-trim {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   max-width: 20vw;
   min-width: 50px;
 }
  .cancel-btn-pos {
    margin-top: -7px;
  }

  .pointer-events-none {
    pointer-events: none !important;
  }
  .user-profile-link-custom{
    background-color: #eceff4;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    padding: 1px;
  }
  
  .user-profile-link-custom img{
    display: block !important;
  }

  .is-locked {
    .consensus-table, .consensus-modules {
      input {
        background-color: white !important;
        border: none !important;
        text-align: center;
        width: 100% !important;
      }
    }
    .ng-select {
      .ng-select-container {
        border: none !important;
        &:hover {
          box-shadow: none !important;
        }
      }
      .ng-arrow-wrapper {
        display: none !important;
      }
    }
    .form-control {
      border: none !important;
      background-color: transparent;
      padding-left: 0;
    }
  }

.custom-popover {
  background-color: #000;
  .arrow::after {
    border-right-color: #000;
  }
}

.no-shadow {
  &:active, &:focus {
    box-shadow: none !important;
  }
}
